import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout.js'


const WpPageTemplate = ({ data: { wpPage } }) => {
  const { title, content  } = wpPage;

  return (
    <Layout>
    <div id="pageTitle">
      <h1>{title}</h1>
    </div>
    <div className="breadClumb">
      <ul>
        <li><a href="#">HOME</a></li>
        <li><a href="#">協会について</a></li>
        <li>{title}</li>
      </ul>
    </div>
    <div id="main">
      <div className="contents">
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  </Layout>
  );
};

export const query = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
    }
  }
`;

export default WpPageTemplate;